/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ style, problemType, whichNetwork1, activeUnitSystem, soilContact, belowMAT, p1H2PartialPressure, processUnit, p1Temperature, operatingTemperatureKnown }) {
  return (
    <div className="cf w-100">
      <div className="fl w-third-l w-50-m w-100">
        <E2GSelectField name="processUnit" value={processUnit} label="Process Unit">
          <E2GSelectFieldOption label="Unknown" value="unknown" />
          <E2GSelectFieldOption label="Amine Treating" value="Amine Treating" />
          <E2GSelectFieldOption label="Catalytic Reforming - CCR" value="Catalytic Reforming - CCR" />
          <E2GSelectFieldOption label="Catalytic Reforming - Fixed Bed" value="Catalytic Reforming - Fixed Bed" />
          <E2GSelectFieldOption label="Crude Unit" value="Crude Unit" />
          <E2GSelectFieldOption label="Delayed Coker" value="Delayed Coker" />
          <E2GSelectFieldOption label="FCC" value="FCC" />
          <E2GSelectFieldOption label="FCC Light Ends Recovery" value="FCC Light Ends Recovery" />
          <E2GSelectFieldOption label="HF Alky" value="HF Alky" />
          <E2GSelectFieldOption label="Hydrogen Reforming" value="Hydrogen Reforming" />
          <E2GSelectFieldOption label="Hydroprocessing" value="Hydroprocessing" />
          <E2GSelectFieldOption label="Isomerization" value="Isomerization" />
          <E2GSelectFieldOption label="Sour Water Stripper" value="Sour Water Stripper" />
          <E2GSelectFieldOption label="Sulfer Recovery" value="Sulfer Recovery" />
          <E2GSelectFieldOption label="Sulfuric Acid Alkylation" value="Sulfuric Acid Alkylation" />
        </E2GSelectField>
        <E2GSelectField name="operatingTemperatureKnown" value={operatingTemperatureKnown} label="Is Operating Temperature Known?">
          <E2GSelectFieldOption label="Yes" value="Yes" />
          <E2GSelectFieldOption label="No" value="No" />
        </E2GSelectField>
        {whichNetwork1 === 'Cracking'
          ? (
            <div>
              {operatingTemperatureKnown === 'Yes'
                ? (
                  <E2GTextField name="p1Temperature" value={p1Temperature} label="Operating Temperature" type="number" required />
                )
                : ''}
              {activeUnitSystem !== 'Metric'
                ? (<div>
                  <E2GSelectField name="p1H2PartialPressure" value={p1H2PartialPressure} label="H2 Partial Pressure">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="<= 50 psi or None" value="<= 50 psia or None" />
                    <E2GSelectFieldOption label="> 50 psi" value="> 50 psia" />
                  </E2GSelectField>
                </div>
                )
                : <div>
                  <E2GSelectField name="p1H2PartialPressure" value={p1H2PartialPressure} label="H2 Partial Pressure">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="<= 3.45 bar or None" value="<= 50 psia or None" />
                    <E2GSelectFieldOption label="> 3.45 bar" value="> 50 psia" />
                  </E2GSelectField>
                </div>}
              <E2GSelectField name="belowMAT" value={belowMAT} label="Material Operates Below MAT">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
            </div>
          )
          : ''}
        {whichNetwork1 === 'Thinning'
          ? (
            <div>
              {operatingTemperatureKnown === 'Yes'
                ? (
                  <E2GTextField name="p1Temperature" value={p1Temperature} label="Operating Temperature" type="number" required />
                )
                : ''}
              {activeUnitSystem !== 'Metric'
                ? (
                  <div>
                    <E2GSelectField name="p1H2PartialPressure" value={p1H2PartialPressure} label="H2 Partial Pressure">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="<= 50 psi or None" value="<= 50 psia or None" />
                      <E2GSelectFieldOption label="> 50 psi" value="> 50 psia" />
                    </E2GSelectField>
                  </div>
                )
                : <div>
                  <E2GSelectField name="p1H2PartialPressure" value={p1H2PartialPressure} label="H2 Partial Pressure">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="<= 3.45 bar or None" value="<= 50 psia or None" />
                    <E2GSelectFieldOption label="> 3.45 bar" value="> 50 psia" />
                  </E2GSelectField>
                </div>}
              <E2GSelectField name="belowMAT" value={belowMAT} label="Material Operates Below MAT">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              <E2GSelectField name="soilContact" value={soilContact} label="Soil Contact">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
            </div>
          )
          : ''}
      </div>
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}
Step1.propTypes = {
  style: PropTypes.object,
  activeUnitSystem: PropTypes.string,
  problemType: PropTypes.string,
  whichNetwork1: PropTypes.string,
  p1H2PartialPressure: PropTypes.string,
  belowMAT: PropTypes.string,
  soilContact: PropTypes.string,
  processUnit: PropTypes.string,
  p1Temperature: PropTypes.number,
  operatingTemperatureKnown: PropTypes.string,
}

const mapStateToProps = state => ({
  style: state.ui.style,
  activeUnitSystem: state.workflow.activeUnitSystem,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  p1H2PartialPressure: state.workflow.fields.p1H2PartialPressure,
  belowMAT: state.workflow.fields.belowMAT,
  soilContact: state.workflow.fields.soilContact,
  processUnit: state.workflow.fields.processUnit,
  p1Temperature: state.workflow.fields.p1Temperature,
  operatingTemperatureKnown: state.workflow.fields.operatingTemperatureKnown,
})

export default connect(mapStateToProps)(Step1)
