import React, { Component } from 'react'
import { connect } from 'react-redux'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'

class Step4 extends Component {
  static propTypes = {
    style: PropTypes.object,
    results: PropTypes.object,
    problemType: PropTypes.string,
    whichNetwork1: PropTypes.string,
    whichNetwork2: PropTypes.string,
    whichNetwork21: PropTypes.string,
    whichNetwork2a: PropTypes.string,
    whichNetwork2b: PropTypes.string,
    crackingResultsTable: PropTypes.object,
    thiningResultsTable: PropTypes.object,
    graphs: PropTypes.object,
    componentAgeVsInstallDateBool: PropTypes.bool,
    previousJobFlag: PropTypes.string,
    inspectionsFlag: PropTypes.string,
    calculateRiskFlag: PropTypes.bool,
  }

  render() {
    const {
      style,
      results,
      problemType,
      whichNetwork1,
      whichNetwork2,
      whichNetwork21,
      whichNetwork2a,
      whichNetwork2b,
      crackingResultsTable,
      thiningResultsTable,
      graphs,
      componentAgeVsInstallDateBool,
      previousJobFlag,
      inspectionsFlag,
      calculateRiskFlag,
    } = this.props
    let graphsToDisplay
    let graphsToDisplay1
    if(problemType === 'PROB2') {
      if((((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')) {
        if(results.previousOutFlag[0] === 1 && results.currentOutFlag[0] === 1) {
          if(calculateRiskFlag === true) {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsPrev', 'graphThicknessProjectionsCurr', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFPrev', 'failureTimeCDFCurr', 'failureTimeCDFUpdated', 'riskTime', 'riskTimePrev', 'riskTimeCurr', 'riskTimeUpdated'])
          } else {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsPrev', 'graphThicknessProjectionsCurr', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFPrev', 'failureTimeCDFCurr', 'failureTimeCDFUpdated'])
          }
        } else if(results.previousOutFlag[0] === 1 && results.currentOutFlag[0] === 0) {
          if(calculateRiskFlag === true) {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsPrev', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFPrev', 'failureTimeCDFUpdated', 'riskTime', 'riskTimePrev', 'riskTimeUpdated'])
          } else {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsPrev', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFPrev', 'failureTimeCDFUpdated'])
          }
        } else if(results.previousOutFlag[0] === 0 && results.currentOutFlag[0] === 1) {
          if(calculateRiskFlag === true) {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsCurr', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFCurr', 'failureTimeCDFUpdated', 'riskTime', 'riskTimeCurr', 'riskTimeUpdated'])
          } else {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsCurr', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFCurr', 'failureTimeCDFUpdated'])
          }
        } else {
          if(calculateRiskFlag === true) {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFUpdated', 'riskTime', 'riskTimeUpdated'])
          } else {
            graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'graphThicknessProjectionsUpdated', 'failureTimeCDF', 'failureTimeCDFUpdated'])
          }
        }
        if(inspectionsFlag !== 'no') {
          const graphToAppend = pick(graphs, ['conditionalPOF'])
          graphsToDisplay = { ...graphsToDisplay, ...graphToAppend }
        }
      } else {
        if((((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true) {
          graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'failureTimeCDF', 'riskTime'])
        } else {
          graphsToDisplay = pick(graphs, ['graphThicknessProjections', 'failureTimeCDF'])
        }
      }
      if(results.plotDamageFactorBool[0] === '1') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjections'])
      } else if(results.plotDamageFactorBool[0] === '1NormalMcVay') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjectionsNormal', 'graphDFProjectionsMcVay', 'graphDFProjectionsCombined'])
      } else if(results.plotDamageFactorBool[0] === '1NormalDobis') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjectionsNormal', 'graphDFProjectionsDobis', 'graphDFProjectionsCombined'])
      } else if(results.plotDamageFactorBool[0] === '1NormalExpert') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjectionsNormal', 'graphDFProjectionsExpert', 'graphDFProjectionsCombined'])
      } else if(results.plotDamageFactorBool[0] === '1NormalExpertMcVay') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjectionsNormal', 'graphDFProjectionsExpert', 'graphDFProjectionsMcVay1', 'graphDFProjectionsCombined'])
      } else if(results.plotDamageFactorBool[0] === '1NormalExpertDobis') {
        graphsToDisplay1 = pick(graphs, ['graphDFProjectionsNormal', 'graphDFProjectionsExpert', 'graphDFProjectionsDobis1', 'graphDFProjectionsCombined'])
      }
    }
    return (
      <div>
        {results
          ? (
            <div>
              {problemType === 'PROB1'
                ? (
                  <div className="cf w-100">
                    {whichNetwork1 === 'Cracking'
                      ? (
                        <div className="cf w-100">
                          <div className="fl w-50-l w-50-m w-100">
                            <DataTableDisplay
                              id="crackingResultsTable"
                              table={crackingResultsTable}
                              colHeaders={{
                                USCustomary: [
                                  'Damage Mechanism',
                                  'Probability (%)',
                                ],
                                Metric: [
                                  'Damage Mechanism',
                                  'Probability (%)',
                                ],
                              }}
                            />
                          </div>
                        </div>
                      )
                      : ''}
                    {whichNetwork1 === 'Thinning'
                      ? (
                        <div className="cf w-100">
                          <div className="fl w-25-l w-50-m w-100">
                            <E2GTextField name="localThinningProb" style={style} value={results.localThinningProb} label="Local Thinning Probability" type="number" textWidth="90%" disabled />
                          </div>
                          <div className="fl w-50-l w-50-m w-100">
                            <DataTableDisplay
                              id="thiningResultsTable"
                              table={thiningResultsTable}
                              colHeaders={{
                                USCustomary: [
                                  'Damage Mechanism',
                                  'Probability (%)',
                                ],
                                Metric: [
                                  'Damage Mechanism',
                                  'Probability (%)',
                                ],
                              }}
                            />
                          </div>
                        </div>
                      )
                      : ''}
                  </div>
                )
                : ''}
              {problemType === 'PROB2'
                ? (
                  <div className="cf w-100">
                    <div className="fl w-25-l w-50-m w-100">
                      {typeof results.corrosionRateEV !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateEV" style={style} value={results.corrosionRateEV} label="Final Corrosion Rate Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.corrosionRateEVCurr !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateEVCurr" style={style} value={results.corrosionRateEVCurr} label="First Current Job Corrosion Rate Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.corrosionRateEVPrev !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateEVPrev" style={style} value={results.corrosionRateEVPrev} label="Last Previous Job Corrosion Rate Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.baseCorrosionRateEV !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')
                        ? (
                          <E2GTextField name="baseCorrosionRateEV" style={style} value={results.baseCorrosionRateEV} label="Base Corrosion Rate Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.damageFactorEV !== 'undefined'
                        ? (
                          <E2GTextField name="damageFactorEV" style={style} value={results.damageFactorEV} label="Damage Factor Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {/* // {typeof results.susceptibilityEV !== 'undefined'
                      //   ? (
                      //     <E2GTextField name="susceptibilityEV" style={style} value={results.susceptibilityEV} label="Susceptibility Mean" type="number" textWidth="90%" disabled />
                      //   )
                      //   : ''} */}
                      {typeof results.failureTimeEV !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeEV" style={style} value={results.failureTimeEV} label="Final Failure Time Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.failureTimeEVPrev !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeEVPrev" style={style} value={results.failureTimeEVPrev} label="Last Current Job Failure Time Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.failureTimeEVCurr !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeEVCurr" style={style} value={results.failureTimeEVCurr} label="First Previous Job Failure Time Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.baseFailureTimeEV !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')
                        ? (
                          <E2GTextField name="baseFailureTimeEV" style={style} value={results.baseFailureTimeEV} label="Base Failure Time Mean" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.failureProbYes !== 'undefined'
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <div>
                                  <E2GTextField name="failureProbYes" style={style} value={results.failureProbYes} label="Final POF at Component Age" type="number" textWidth="90%" disabled />
                                  {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                    ? (
                                      <E2GTextField name="risk" style={style} value={results.risk} label="Final Risk at Component Age" type="number" textWidth="90%" disabled />
                                    )
                                    : ''}
                                </div>
                              )
                              : <div>
                                <E2GTextField name="failureProbYes" style={style} value={results.failureProbYes} label="Final POF at Analysis Date" type="number" textWidth="90%" disabled />
                                {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                  ? (
                                    <E2GTextField name="risk" style={style} value={results.risk} label="Final Risk at Analysis Date" type="number" textWidth="90%" disabled />
                                  )
                                  : ''}
                              </div>}
                          </div>
                        )
                        : ''}
                      {typeof results.failureProbYesPrev !== 'undefined'
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <div>
                                  <E2GTextField name="failureProbYesPrev" style={style} value={results.failureProbYesPrev} label="Last Previous Job POF at Component Age" type="number" textWidth="90%" disabled />
                                  {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && (calculateRiskFlag === true && typeof results.riskPrev !== 'undefined')
                                    ? (
                                      <E2GTextField name="riskPrev" style={style} value={results.riskPrev} label="Last Previous Job Risk at Component Age" type="number" textWidth="90%" disabled />
                                    )
                                    : ''}
                                </div>
                              )
                              : <div>
                                <E2GTextField name="failureProbYesPrev" style={style} value={results.failureProbYesPrev} label="Last Previous Job POF at Analysis Date" type="number" textWidth="90%" disabled />
                                {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && (calculateRiskFlag === true && typeof results.riskPrev !== 'undefined')
                                  ? (
                                    <E2GTextField name="riskPrev" style={style} value={results.riskPrev} label="Last Previous Job Risk at Analysis Date" type="number" textWidth="90%" disabled />
                                  )
                                  : ''}
                              </div>}
                          </div>
                        )
                        : ''}
                      {typeof results.cracksProbYes !== 'undefined'
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <E2GTextField name="cracksProbYes" style={style} value={results.cracksProbYes} label="Probability of Cracking at Component Age" type="number" textWidth="90%" disabled />
                              )
                              : <E2GTextField name="cracksProbYes" style={style} value={results.cracksProbYes} label="Probability of Cracking at Analysis Date" type="number" textWidth="90%" disabled />}
                          </div>
                        )
                        : ''}
                      {typeof results.survivalPof !== 'undefined'
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <div>
                                  <E2GTextField name="survivalPof" style={style} value={results.survivalPof} label="Final Survival POF at Component Age" type="number" textWidth="90%" disabled />
                                  {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                    ? (
                                      <E2GTextField name="survivalRisk" style={style} value={results.survivalRisk} label="Final Survival Risk at Component Age" type="number" textWidth="90%" disabled />
                                    )
                                    : ''}
                                </div>
                              )
                              : <div>
                                <E2GTextField name="survivalPof" style={style} value={results.survivalPof} label="Final Survival POF at Analysis Date" type="number" textWidth="90%" disabled />
                                {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                  ? (
                                    <E2GTextField name="survivalRisk" style={style} value={results.survivalRisk} label="Final Survival Risk at Analysis Date" type="number" textWidth="90%" disabled />
                                  )
                                  : ''}
                              </div>}
                          </div>
                        )
                        : ''}
                    </div>
                    <div className="fl w-25-l w-50-m w-100">
                      {typeof results.corrosionRateSD !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateSD" style={style} value={results.corrosionRateSD} label="Final Corrosion Rate SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.corrosionRateSDCurr !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateSDCurr" style={style} value={results.corrosionRateSDCurr} label="First Current Job Corrosion Rate SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.corrosionRateSDPrev !== 'undefined'
                        ? (
                          <E2GTextField name="corrosionRateSDPrev" style={style} value={results.corrosionRateSDPrev} label="Last Previous Job Corrosion Rate SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.baseCorrosionRateSD !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')
                        ? (
                          <E2GTextField name="baseCorrosionRateSD" style={style} value={results.baseCorrosionRateSD} label="Base Corrosion Rate SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.damageFactorSD !== 'undefined'
                        ? (
                          <E2GTextField name="damageFactorSD" style={style} value={results.damageFactorSD} label="Damage Factor Standard Deviation" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {/* // {typeof results.susceptibilitySD !== 'undefined'
                      //   ? (
                      //     <E2GTextField name="susceptibilitySD" style={style} value={results.susceptibilitySD} label="Susceptibility Standard Deviation" type="number" textWidth="90%" disabled />
                      //   )
                      //   : ''} */}
                      {typeof results.failureTimeSD !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeSD" style={style} value={results.failureTimeSD} label="Final Failure Time SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.failureTimeSDPrev !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeSDPrev" style={style} value={results.failureTimeSDPrev} label="First Current Job Failure Time SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.failureTimeSDCurr !== 'undefined'
                        ? (
                          <E2GTextField name="failureTimeSDCurr" style={style} value={results.failureTimeSDCurr} label="Last Previous Job Failure Time SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.baseFailureTimeSD !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')
                        ? (
                          <E2GTextField name="baseFailureTimeSD" style={style} value={results.baseFailureTimeSD} label="Base Failure Time SD" type="number" textWidth="90%" disabled />
                        )
                        : ''}
                      {typeof results.baseFailureProbYes !== 'undefined' && (previousJobFlag === 'yes' || inspectionsFlag !== 'no')
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <div>
                                  <E2GTextField name="baseFailureProbYes" style={style} value={results.baseFailureProbYes} label="Base POF at Component Age" type="number" textWidth="90%" disabled />
                                  {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                    ? (
                                      <E2GTextField name="riskBase" style={style} value={results.riskBase} label="Base Risk at Component Age" type="number" textWidth="90%" disabled />
                                    )
                                    : ''}
                                </div>
                              )
                              : <div>
                                <E2GTextField name="baseFailureProbYes" style={style} value={results.baseFailureProbYes} label="Base POF at Analysis Date" type="number" textWidth="90%" disabled />
                                {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && calculateRiskFlag === true
                                  ? (
                                    <E2GTextField name="riskBase" style={style} value={results.riskBase} label="Base Risk at Analysis Date" type="number" textWidth="90%" disabled />
                                  )
                                  : ''}
                              </div>}
                          </div>
                        )
                        : ''}
                      {typeof results.failureProbYesCurr !== 'undefined'
                        ? (
                          <div>
                            {componentAgeVsInstallDateBool === true
                              ? (
                                <div>
                                  <E2GTextField name="failureProbYesCurr" style={style} value={results.failureProbYesCurr} label="First Current Job POF at Component Age" type="number" textWidth="90%" disabled />
                                  {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && (calculateRiskFlag === true && typeof results.riskCurr !== 'undefined')
                                    ? (
                                      <E2GTextField name="riskCurr" style={style} value={results.riskCurr} label="First Current Job Risk at Component Age" type="number" textWidth="90%" disabled />
                                    )
                                    : ''}
                                </div>
                              )
                              : <div>
                                <E2GTextField name="failureProbYesCurr" style={style} value={results.failureProbYesCurr} label="First Current Job POF at Analysis Date" type="number" textWidth="90%" disabled />
                                {(((whichNetwork2b === 'Sulfidation' || whichNetwork2b === 'Other Corrosion' || whichNetwork2b === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'Thinning') || ((whichNetwork2 === 'Sulfidation' || whichNetwork2 === 'Other Corrosion' || whichNetwork2 === 'Ammonium Chloride Corrosion') && whichNetwork21 === 'unknown')) && (calculateRiskFlag === true && typeof results.riskCurr !== 'undefined')
                                  ? (
                                    <E2GTextField name="riskCurr" style={style} value={results.riskCurr} label="First Current Job Risk at Analysis Date" type="number" textWidth="90%" disabled />
                                  )
                                  : ''}
                              </div>}
                          </div>
                        )
                        : ''}
                    </div>
                    <div className="fl w-50-l w-50-m w-100">
                      {typeof results.corrosionRateEV !== 'undefined'
                        ? (
                          <div>
                            {graphs
                              ? <DataGraphDisplay plotClass="data-graph-75" graphs={graphsToDisplay} />
                              : ''}
                          </div>
                        )
                        : ''}
                      {results.plotDamageFactorBool[0] === '1' || results.plotDamageFactorBool[0] === '1NormalMcVay' || results.plotDamageFactorBool[0] === '1NormalDobis' || results.plotDamageFactorBool[0] === '1NormalExpertMcVay' || results.plotDamageFactorBool[0] === '1NormalExpertDobis' || results.plotDamageFactorBool[0] === '1NormalExpert'
                        ? (
                          <div>
                            {graphs
                              ? <DataGraphDisplay plotClass="data-graph-75" graphs={graphsToDisplay1} />
                              : ''}
                          </div>
                        )
                        : ''}
                    </div>
                  </div>
                )
                : ''}
            </div>
          )
          : ('')}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  style: state.ui.style,
  calculateRiskFlag: state.workflow.fields.calculateRiskFlag,
  results: state.workflow.response ? state.workflow.response.results : undefined,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  whichNetwork2: state.workflow.fields.whichNetwork2,
  whichNetwork21: state.workflow.fields.whichNetwork21,
  whichNetwork2a: state.workflow.fields.whichNetwork2a,
  whichNetwork2b: state.workflow.fields.whichNetwork2b,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  crackingResultsTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.crackingResultsTable : undefined,
  thiningResultsTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.thiningResultsTable : undefined,
  graphs: state.workflow.response.results ? state.workflow.response.results.graphs : undefined,
  previousJobFlag: state.workflow.fields.previousJobFlag,
  inspectionsFlag: state.workflow.fields.inspectionsFlag,
})

export default connect(mapStateToProps)(Step4)
