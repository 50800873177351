import React, { PropsWithChildren } from 'react'

import AssetTableParamsProvider from '@e2grnd/ui-core/dist/contexts/AssetTableParamsProvider'

import burstPressureConfig from '../calculators/burstPressure'
import damageSightConfig from '../calculators/damageSight'
import { config as vanWinkleConfig } from '../calculators/vanWinkle'

export default function AssetTableParamsProviderWrapper(props: PropsWithChildren<{}>) {
  return (
    <AssetTableParamsProvider value={{
      vanWinkle: {
        params: vanWinkleConfig.params,
        examples: vanWinkleConfig.examples,
        assetParamsMapFn: vanWinkleConfig.assetParamsMapFn,
        resultParams: ['sleptFor'],
      },
      burstPressure: {
        params: burstPressureConfig.params,
        examples: burstPressureConfig.examples,
        assetComponentParamsMapFn: burstPressureConfig.assetComponentParamsMapFn,
        resultParams: ['BURSTP>PBURSTE'],
      },
      damageSight: {
        params: damageSightConfig.params,
        examples: damageSightConfig.examples,
        assetComponentParamsMapFn: damageSightConfig.assetComponentParamsMapFn,
        resultParams: ['corrosionRateEV', 'failureTimeEV', 'failureProbYes', 'risk'],
      },
    }}
    >
      {props.children}
    </AssetTableParamsProvider>
  )
}
