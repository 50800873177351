import React from 'react'

import AdvancedOptions from './AdvancedOptions'

export default [
  {
    name: 'Prop1',
    title: 'Advanced Options',
    component: <AdvancedOptions />,
  },
]
