/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step2({ style, problemType, whichNetwork1, equipmentType, stainlessType, p1BrinnelHardness, p1Materiala, p1Materialb }) {
  return (
    <div className="cf w-100">
      <div className="fl w-third-l w-50-m w-100">
        {whichNetwork1 === 'Cracking'
          ? (
            <div>
              <E2GSelectField name="p1Materiala" value={p1Materiala} label="Material of Construction">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Carbon Steel" value="Carbon Steel" />
                <E2GSelectFieldOption label="Low Alloy" value="Low Alloy" />
                <E2GSelectFieldOption label="5Cr Alloy" value="5Cr Alloy" />
                <E2GSelectFieldOption label="9Cr Alloy" value="9Cr Alloy" />
                <E2GSelectFieldOption label="12Cr Alloy" value="12Cr Alloy" />
                <E2GSelectFieldOption label="Stainless" value="Stainless" />
                <E2GSelectFieldOption label="Ni Alloy" value="Ni Alloy" />
              </E2GSelectField>
              {p1Materiala === 'Stainless'
                ? (
                  <E2GSelectField name="stainlessType" value={stainlessType} label="Stainless Type">
                    <E2GSelectFieldOption label="Austenitic" value="Austenitic" />
                    <E2GSelectFieldOption label="Ferritic" value="Ferritic" />
                  </E2GSelectField>
                )
                : ''}
              <E2GSelectField name="p1BrinnelHardness" value={p1BrinnelHardness} label="Brinnel Hardness">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label=">200" value=">200" />
                <E2GSelectFieldOption label="<200 Non-PWHT" value="<200 Non-PWHT" />
                <E2GSelectFieldOption label="<200 PWHT" value="<200 PWHT" />
              </E2GSelectField>
            </div>
          )
          : ''}
        {whichNetwork1 === 'Thinning'
          ? (
            <div>
              <E2GSelectField name="equipmentType" value={equipmentType} label="Equipment Type">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Fixed Equipment" value="Fixed Equipment" />
                <E2GSelectFieldOption label="Piping" value="Piping" />
                <E2GSelectFieldOption label="Tank" value="Tank" />
                <E2GSelectFieldOption label="Bundle" value="Bundle" />
                <E2GSelectFieldOption label="PRD" value="PRD" />
              </E2GSelectField>
              <E2GSelectField name="p1Materialb" value={p1Materialb} label="Material of Construction">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Carbon Steel" value="Carbon Steel" />
                <E2GSelectFieldOption label="Low Allow" value="Low Allow" />
                <E2GSelectFieldOption label="5Cr Alloy" value="5Cr" />
                <E2GSelectFieldOption label="9Cr Alloy" value="9Cr" />
                <E2GSelectFieldOption label="13Cr Alloy" value="13Cr" />
                <E2GSelectFieldOption label="Stainless" value="SS" />
                <E2GSelectFieldOption label="Ni Alloys" value="Ni-Alloys" />
                <E2GSelectFieldOption label="HP Alloys" value="HP Alloys" />
              </E2GSelectField>
              {p1Materialb === 'SS'
                ? (
                  <E2GSelectField name="stainlessType" value={stainlessType} label="Stainless Type">
                    <E2GSelectFieldOption label="Austenitic" value="Austenitic" />
                    <E2GSelectFieldOption label="Ferritic" value="Ferritic" />
                  </E2GSelectField>
                )
                : ''}
              <E2GSelectField name="p1BrinnelHardness" value={p1BrinnelHardness} label="Brinnel Hardness">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label=">200" value=">200" />
                <E2GSelectFieldOption label="<200 Non-PWHT" value="<200 Non-PWHT" />
                <E2GSelectFieldOption label="<200 PWHT" value="<200 PWHT" />
              </E2GSelectField>
            </div>
          )
          : ''}
      </div>
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}
Step2.propTypes = {
  style: PropTypes.object,
  problemType: PropTypes.string,
  whichNetwork1: PropTypes.string,
  p1BrinnelHardness: PropTypes.string,
  stainlessType: PropTypes.string,
  equipmentType: PropTypes.string,
  p1Materiala: PropTypes.string,
  p1Materialb: PropTypes.string,
}

const mapStateToProps = state => ({
  style: state.ui.style,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  p1BrinnelHardness: state.workflow.fields.p1BrinnelHardness,
  stainlessType: state.workflow.fields.stainlessType,
  equipmentType: state.workflow.fields.equipmentType,
  p1Materiala: state.workflow.fields.p1Materiala,
  p1Materialb: state.workflow.fields.p1Materialb,
})

export default connect(mapStateToProps)(Step2)
