import { getColumnFrom2DArray } from '@e2grnd/ui-core/dist/core/lib'

export function getReplacementsCracking(table, col0RowNames) {
  const inp1 = getColumnFrom2DArray(table, 1)
  const newTable = []
  for(let i = 0; i < inp1.length; i += 1) {
    const row = [col0RowNames[i], inp1[i]]
    newTable.push(row)
  }
  return newTable
}

export function getReplacementsThinning(table, col0RowNames) {
  const inp1 = getColumnFrom2DArray(table, 1)
  const newTable = []
  for(let i = 0; i < inp1.length; i += 1) {
    const row = [col0RowNames[i], inp1[i]]
    newTable.push(row)
  }
  return newTable
}

export function getReplacements(table) {
  const inp0 = getColumnFrom2DArray(table, 0)
  const inp1 = getColumnFrom2DArray(table, 1)
  const inp2 = getColumnFrom2DArray(table, 2)
  const inp3 = getColumnFrom2DArray(table, 3)
  const inp4 = getColumnFrom2DArray(table, 4)
  const inp5 = getColumnFrom2DArray(table, 5)
  const inp6 = getColumnFrom2DArray(table, 6)
  const inp7 = getColumnFrom2DArray(table, 7)
  const inp8 = getColumnFrom2DArray(table, 8)
  const newTable = []
  for(let i = 0; i < inp0.length; i += 1) {
    const row = [inp0[i], inp1[i], inp2[i], inp3[i], inp4[i], inp5[i], inp6[i], inp7[i], inp8[i]]
    if(((typeof inp6[i] !== 'undefined' && inp6[i] !== null) && inp6[i] !== '') && inp6[i] === 0) {
      row[7] = 'N/A'
    } else if(((typeof inp6[i] !== 'undefined' && inp6[i] !== null) && inp6[i] !== '') && inp6[i] > 0) {
      if(row[7] === 'N/A') {
        row[7] = ''
      }
    }
    newTable.push(row)
  }
  return newTable
}
