/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step3({ style, problemType, whichNetwork1, p1PH, fluidType, contaminants, p1Velocity, h2Present, hclPresent, crudeOil, sulfuricAcidPresent, ammoniumBisulfide, p1O2Present, coolingWater, co2Present, waterPresent, freeWaterPresent, h2SPresent, naOhPresent, sulfurPresent, chloridesPresent, p1HfPresent, aminePresent, pHKnown }) {
  return (
    <div className="cf w-100">
      {whichNetwork1 === 'Cracking' || whichNetwork1 === 'Thinning'
        ? (
          <div className="cf w-100">
            <div className="fl w-20-l w-50-m w-100">
              <E2GSelectField name="fluidType" value={fluidType} label="Fluid Type">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Primarily Hydrocarbons" value="Primarily Hydrocarbons" />
                <E2GSelectFieldOption label="Sour Water" value="Sour Water" />
                <E2GSelectFieldOption label="Lean Amine" value="Lean Amine" />
                <E2GSelectFieldOption label="Rich Amine" value="Rich Amine" />
                <E2GSelectFieldOption label="Free Caustic" value="Free Caustic" />
              </E2GSelectField>
              <E2GSelectField name="contaminants" value={contaminants} label="Contaminants">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="H2S PPM" value="H2S PPM" />
                <E2GSelectFieldOption label="Chlorides" value="Chlorides" />
                <E2GSelectFieldOption label="Caustic" value="Caustic" />
                <E2GSelectFieldOption label="Free Water" value="Free Water" />
              </E2GSelectField>
              {whichNetwork1 === 'Thinning'
                ? (
                  <div>
                    <E2GSelectField name="p1Velocity" value={p1Velocity} label="Velocity">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Low" value="Low" />
                      <E2GSelectFieldOption label="High" value="High" />
                    </E2GSelectField>
                  </div>
                )
                : ''}
              <E2GSelectField name="pHKnown" value={pHKnown} label="Is pH Known?">
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {pHKnown === 'Yes'
                ? (
                  <E2GTextField name="p1PH" value={p1PH} label="pH" type="number" required />
                )
                : ''}
            </div>
            {whichNetwork1 === 'Cracking'
              ? (
                <div>
                  <div className="fl w-20-l w-50-m w-100">
                    <E2GSelectField name="aminePresent" value={aminePresent} label="Amine Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="p1HfPresent" value={p1HfPresent} label="HF Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="chloridesPresent" value={chloridesPresent} label="Chlorides Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                  <div className="fl w-20-l w-50-m w-100">
                    <E2GSelectField name="sulfurPresent" value={sulfurPresent} label="Sulfur Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="naOhPresent" value={naOhPresent} label="NaOH Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="h2SPresent" value={h2SPresent} label="H2S Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="freeWaterPresent" value={freeWaterPresent} label="Free Water Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                </div>
              )
              : ''}
            {whichNetwork1 === 'Thinning'
              ? (
                <div className="cf w-100">
                  <div className="fl w-20-l w-50-m w-100">
                    <E2GSelectField name="aminePresent" value={aminePresent} label="Amine Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="p1HfPresent" value={p1HfPresent} label="HF Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="waterPresent" value={waterPresent} label="Water Present in Stream">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="co2Present" value={co2Present} label="CO2 Present in Stream">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                  <div className="fl w-20-l w-50-m w-100">
                    <E2GSelectField name="coolingWater" value={coolingWater} label="Cooling Water">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="p1O2Present" value={p1O2Present} label="O2 Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="ammoniumBisulfide" value={ammoniumBisulfide} label="Ammonium Bisulfide Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="sulfuricAcidPresent" value={sulfuricAcidPresent} label="Sulfuric Acid Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                  <div className="fl w-20-l w-50-m w-100">
                    <E2GSelectField name="crudeOil" value={crudeOil} label="Crude Oil Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes TAN <= 0.5" value="Yes TAN <= 0.5" />
                      <E2GSelectFieldOption label="Yes TAN > 0.5" value="Yes TAN > 0.5" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="hclPresent" value={hclPresent} label="HCl Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="h2Present" value={h2Present} label="H2 Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="sulfurPresent" value={sulfurPresent} label="Sulfur Present">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}
Step3.propTypes = {
  style: PropTypes.object,
  problemType: PropTypes.string,
  whichNetwork1: PropTypes.string,
  contaminants: PropTypes.string,
  fluidType: PropTypes.string,
  p1Velocity: PropTypes.string,
  p1PH: PropTypes.number,
  aminePresent: PropTypes.string,
  p1HfPresent: PropTypes.string,
  chloridesPresent: PropTypes.string,
  sulfurPresent: PropTypes.string,
  naOhPresent: PropTypes.string,
  h2SPresent: PropTypes.string,
  freeWaterPresent: PropTypes.string,
  waterPresent: PropTypes.string,
  co2Present: PropTypes.string,
  coolingWater: PropTypes.string,
  p1O2Present: PropTypes.string,
  ammoniumBisulfide: PropTypes.string,
  sulfuricAcidPresent: PropTypes.string,
  crudeOil: PropTypes.string,
  hclPresent: PropTypes.string,
  h2Present: PropTypes.string,
  pHKnown: PropTypes.string,
}

const mapStateToProps = state => ({
  style: state.ui.style,
  problemType: state.workflow.fields.problemType,
  whichNetwork1: state.workflow.fields.whichNetwork1,
  contaminants: state.workflow.fields.contaminants,
  fluidType: state.workflow.fields.fluidType,
  p1Velocity: state.workflow.fields.p1Velocity,
  p1PH: state.workflow.fields.p1PH,
  aminePresent: state.workflow.fields.aminePresent,
  p1HfPresent: state.workflow.fields.p1HfPresent,
  chloridesPresent: state.workflow.fields.chloridesPresent,
  sulfurPresent: state.workflow.fields.sulfurPresent,
  naOhPresent: state.workflow.fields.naOhPresent,
  h2SPresent: state.workflow.fields.h2SPresent,
  freeWaterPresent: state.workflow.fields.freeWaterPresent,
  waterPresent: state.workflow.fields.waterPresent,
  co2Present: state.workflow.fields.co2Present,
  coolingWater: state.workflow.fields.coolingWater,
  p1O2Present: state.workflow.fields.p1O2Present,
  ammoniumBisulfide: state.workflow.fields.ammoniumBisulfide,
  sulfuricAcidPresent: state.workflow.fields.sulfuricAcidPresent,
  crudeOil: state.workflow.fields.crudeOil,
  hclPresent: state.workflow.fields.hclPresent,
  h2Present: state.workflow.fields.h2Present,
  pHKnown: state.workflow.fields.pHKnown,
})

export default connect(mapStateToProps)(Step3)
