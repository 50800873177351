import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import subSteps1a from './subSteps1a'
import subSteps1b from './subSteps1b'
import subSteps1c from './subSteps1c'

export default [
  {
    name: 'Step1',
    title: {
      PROB1: 'Damage Morphology',
      PROB2: 'Damage Morphology',
    },
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: {
      PROB1: 'Conditionals',
      PROB2: 'Conditionals',
    },
    content: (
      <Step2 />
    ),
    subSteps: [subSteps1a, subSteps1b, subSteps1c],
  },
  {
    name: 'Step3',
    title: {
      PROB1: 'Exper Prior Information',
      PROB2: 'Exper Prior Information',
    },
    content: (
      <Step3 />
    ),
  },
  {
    name: 'Step4',
    title: {
      PROB1: 'Results',
      PROB2: 'Results',
    },
    content: (
      <Step4 />
    ),
  },
]
