import React from 'react'

import Problems from './Problems'

export default [
  {
    name: 'Problems',
    content: (
      <Problems />
    ),
  },
]
