/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
// import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'

import * as histogram from '../lib/histogram'

const Graphs = ({ results, graphs, activeUnitSystem, problemType, previousJobFlag, inspectionsFlag }) => {
  const graphsToDisplay = histogram.plotBarResults(results, activeUnitSystem, previousJobFlag, inspectionsFlag)
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        <DataGraphDisplay graphs={graphsToDisplay} />
      </div>
    </div>
  )
}

Graphs.propTypes = {
  results: PropTypes.object,
  graphs: PropTypes.object,
  activeUnitSystem: PropTypes.string,
  problemType: PropTypes.string,
  previousJobFlag: PropTypes.string,
  inspectionsFlag: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
  activeUnitSystem: state.workflow.activeUnitSystem,
  problemType: state.workflow.fields.problemType,
  previousJobFlag: state.workflow.fields.previousJobFlag,
  inspectionsFlag: state.workflow.fields.inspectionsFlag,
})
export default connect(mapStateToProps, null)(Graphs)
