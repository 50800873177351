export default [
  {
    traces: [
      {
        keyword: 'lowerBoundThicknessProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianThicknessProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundThicknessProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphThicknessProjections',
    title: 'Base Thickness Projections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundThicknessProjectionTraceUpdated',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianThicknessProjectionTraceUpdated',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundThicknessProjectionTraceUpdated',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphThicknessProjectionsUpdated',
    title: 'Final Thickness Projections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundThicknessProjectionTracePrev',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianThicknessProjectionTracePrev',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundThicknessProjectionTracePrev',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphThicknessProjectionsPrev',
    title: 'Last Previous Job Thickness Projections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundThicknessProjectionTraceCurr',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianThicknessProjectionTraceCurr',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundThicknessProjectionTraceCurr',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphThicknessProjectionsCurr',
    title: 'First Current Job Thickness Projections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTraceAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDF',
    title: 'Base Failure Time CDF',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTraceAfterInspectionsUpdated',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentileAfterInspectionsUpdated',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentileAfterInspectionsUpdated',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentileAfterInspectionsUpdated',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentileAfterInspectionsUpdated',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDFUpdated',
    title: 'Final Failure Time CDF',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTraceAfterInspectionsPrev',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentileAfterInspectionsPrev',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentileAfterInspectionsPrev',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentileAfterInspectionsPrev',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentileAfterInspectionsPrev',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDFPrev',
    title: 'Last Previous Job Failure Time CDF',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTraceAfterInspectionsCurr',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentileAfterInspectionsCurr',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentileAfterInspectionsCurr',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentileAfterInspectionsCurr',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentileAfterInspectionsCurr',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDFCurr',
    title: 'First Current Job Failure Time CDF',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'conditionalPOF',
        mode: 'lines',
        type: 'scatter',
        title: 'Risk',
        hoverinfo: 'all',
      },
    ],
    name: 'conditionalPOF',
    title: 'Final Conditional POF',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'riskTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Risk',
        hoverinfo: 'all',
      },
    ],
    name: 'riskTime',
    title: 'Base Risk',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Risk',
        Metric: 'Risk',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'riskTraceUpdated',
        mode: 'lines',
        type: 'scatter',
        title: 'Risk',
        hoverinfo: 'all',
      },
    ],
    name: 'riskTimeUpdated',
    title: 'Final Risk',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Risk',
        Metric: 'Risk',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'riskTracePrev',
        mode: 'lines',
        type: 'scatter',
        title: 'Risk',
        hoverinfo: 'all',
      },
    ],
    name: 'riskTimePrev',
    title: 'Last Previous Job Risk',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Risk',
        Metric: 'Risk',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'riskTraceCurr',
        mode: 'lines',
        type: 'scatter',
        title: 'Risk',
        hoverinfo: 'all',
      },
    ],
    name: 'riskTimeCurr',
    title: 'First Current Job Risk',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Risk',
        Metric: 'Risk',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjections',
    title: 'Component Damage Factor Projections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceCombined',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceCombined',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceCombined',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsCombined',
    title: 'Component Damage Factor Projections (Combined)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceNormal',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceNormal',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceNormal',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsNormal',
    title: 'Component Damage Factor Projections (API 581)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsMcVay',
    title: 'Component Damage Factor Projections (McVay)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsDobis',
    title: 'Component Damage Factor Projections (Dobis)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },

  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsMcVay',
    title: 'Component Damage Factor Projections (McVay)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceSecond',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsExpert',
    title: 'Component Damage Factor Projections (Expert)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },

  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsMcVay1',
    title: 'Component Damage Factor Projections (McVay)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'lowerBoundDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'medianDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'upperBoundDFProjectionTraceThird',
        mode: 'lines',
        type: 'scatter',
        title: 'Upper Bound (90%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
    ],
    name: 'graphDFProjectionsDobis1',
    title: 'Component Damage Factor Projections (Dobis)',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Damage Factor',
        Metric: 'Damage Factor',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },

]
