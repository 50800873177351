import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import OpenStepper from '@e2grnd/ui-core/dist/containers/OpenStepper'
import RadioField, { RadioFieldOption } from '@e2grnd/ui-core/dist/containers/RadioField'

export const problemTypeLup = {
  PROB1: 'Scenario 1',
  PROB2: 'Scenario 2',
}

const Problems = ({ problemType }) => (
  <div>
    <RadioField name="problemType" value={problemType} reInitialize label="Problem Types" >
      <RadioFieldOption value="PROB1" label="Determine the Most Likely Damage Mechanism" />
      <RadioFieldOption value="PROB2" label="Calculate the Damage Rate per Damage Mechanism" />
    </RadioField>
    <OpenStepper label="Next" name="problemType" value={problemType} />
  </div>
)

Problems.propTypes = {
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(Problems)
