import React from 'react'

import BayesNetBuilderProp from './BayesNetBuilder'
import Histograms from './Histograms'
import POFAtTimes from './POFAtTimes'

export default [
  {
    name: 'Prop1',
    title: 'Histograms',
    component: <Histograms/>,
    predicate: fields => fields.problemType === 'PROB2',
  },
  {
    name: 'Prop2',
    title: 'POFs at Various Times',
    component: <POFAtTimes/>,
    predicate: fields => fields.problemType === 'PROB2' && fields.whichNetwork21 !== 'Cracking',
  },
  {
    name: 'Prop3',
    title: 'Bayes Net Builder',
    component: <BayesNetBuilderProp/>,
  },
]
